import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layouts/default"

import Badge from "../components/badge"
import Breadcrumb from "../components/breadcrumb"
import Constrain from "../components/constrain"
import Paragraph from "../components/paragraph"
import Heading from "../components/heading"
import Inline from "../components/inline"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import VideoPlayer from "../components/video-player"

const Anreise = () => {
  const data = useStaticQuery(graphql`
    query KontaktAnreiseQuery {
      posterBus: file(relativePath: { eq: "videos/poster-bus.png" }) {
        ...largeImage
      }
      posterAuto: file(relativePath: { eq: "videos/poster-auto.png" }) {
        ...largeImage
      }
      posterZug: file(relativePath: { eq: "videos/poster-zug.png" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="wegbeschreibung">
      <Seo
        title="So kommt man zum Hölderlinturm"
        description="Hier finden Sie Wegbeschreibungen und hilfreiche Informationen."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Anreise",
                link: "/anreise",
              },
            ]}
          />
          <PageTitle>So kommt man zum Hölderlinturm</PageTitle>
          <Paragraph>
            Das ist die Adresse vom Hölderlinturm: <br />
            Bursagasse 6 <br />
            72070 Tübingen
          </Paragraph>
          <Paragraph>
            Der Weg zum Turm ist nicht barrierefrei. <br />
            Mit dem Rollstuhl kommt man nicht zum Turm.
          </Paragraph>
        </Stack>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Bus kommen:
            </Heading>
            <VideoPlayer src="wegbeschreibungen/bus" poster={data.posterBus} />
            <Paragraph>
              Mit diesen Linien kommen Sie zum Hölderlinturm:
            </Paragraph>
            <Inline>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                1
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                2
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                3
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                4
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                5
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                6
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                7
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                8
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                9
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                10
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                11
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                12
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                13
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                X15
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                16
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                17
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                19
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                21
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                22
              </Badge>
              <Badge
                bg="contrast"
                color="background"
                size={[2, 3, 4]}
                round={true}
              >
                23
              </Badge>
            </Inline>
            <Paragraph>
              Die Haltestelle heißt ›Neckarbrücke‹. <br />
              Dort müssen Sie aus dem Bus aussteigen.
            </Paragraph>
            <Paragraph>
              An der Neckarbrücke ist ein Haus mit 2 Restaurants. <br />
              Das Restaurant ›La Torre Pizzeria‹ und das Restaurant ›San Bao‹.{" "}
              <br />
              Vielleicht müssen Sie über die Straße gehen, <br />
              damit Sie zu dem Haus kommen. <br />
              Direkt neben dem Haus gibt es eine Treppe. <br />
              Wenn Sie die Treppe nach unten gehen, <br />
              sind Sie unten an der Stadtmauer. <br />
              Wenn Sie an der Stadtmauer entlang gehen, <br />
              kommen Sie zum Turm. <br />
              Am Turm ist ein Schild. <br />
              Auf dem Schild steht ›Museum‹. <br />
              Dort ist der Eingang zum Museum.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Auto kommen:
            </Heading>
            <VideoPlayer
              src="wegbeschreibungen/auto"
              poster={data.posterAuto}
            />
            <Paragraph>
              Sie können im Neckar-Parkhaus parken. <br />
              Die Adresse vom Neckar-Parkhaus ist: <br />
              Wöhrdstraße 11 <br />
              72072 Tübingen
            </Paragraph>
            <Paragraph>
              Wenn Sie aus dem Parkhaus heraus kommen, <br />
              laufen Sie nach rechts. <br />
              Dann kommen Sie zur Neckarbrücke. <br />
              An der Ampel gehen Sie über die Straße. <br />
            </Paragraph>
            <Paragraph>
              An der Neckarbrücke ist ein Haus mit 2 Restaurants. <br />
              Das Restaurant ›La Torre Pizzeria‹ und das Restaurant ›San Bao‹.{" "}
              <br />
              Direkt neben dem Haus gibt es eine Treppe. <br />
              Wenn Sie die Treppe nach unten gehen, <br />
              sind Sie unten an der Stadtmauer. <br />
              Wenn Sie an der Stadtmauer entlang gehen, <br />
              kommen Sie zum Turm. <br />
              Am Turm ist ein Schild. <br />
              Auf dem Schild steht ›Museum‹. <br />
              Dort ist der Eingang zum Museum.
            </Paragraph>
            <Paragraph>
              Im Hölderlinturm gibt es Treppen. <br />
              Es gibt keinen Aufzug. <br />
              Mit dem Rollstuhl können Sie den Hölderlinturm nicht besuchen.
            </Paragraph>
            <Paragraph>
              Im Museum gibt es 2 Toiletten: <br />
              Eine Toilette im Erdgeschoss. <br />
              Und eine Toilette im Obergeschoss.
            </Paragraph>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Wenn Sie mit dem Zug kommen:
            </Heading>
            <VideoPlayer src="wegbeschreibungen/zug" poster={data.posterZug} />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default Anreise
